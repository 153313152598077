.navigation {
  position: relative;
  min-width: 250px;
  background-color: white;
  left: 0;
}
.navigation .list-button {
  position: absolute;
  display: none;
  right: -25px;
  top: 1px;
  width: 25px;
  height: 25px;
  background-color: white;
  outline: 1px solid rgba(128, 128, 128, 0.1098039216);
  text-align: center;
  line-height: 25px;
}
.navigation::after {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.0980392157);
}
.navigation ul {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
  padding: 30px 0;
}
.navigation ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  gap: 10px;
  font-size: 18px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 auto;
  width: 100%;
  max-width: 139px;
}
.navigation ul li:hover {
  opacity: 0.6;
}
.navigation ul li p {
  margin-right: auto;
  color: #333;
}
.navigation ul li i {
  color: rgba(128, 128, 128, 0.6117647059);
  width: 20px;
}
@media (max-width: 850px) {
  .navigation {
    position: absolute;
    left: -250px;
    z-index: 2;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .navigation .list-button {
    display: block;
  }
}