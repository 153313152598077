.other_offers {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.other_offers .cards {
  height: 300px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.other_offers .cards .card {
  height: 100%;
  width: 32%;
  background: #fff;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 5px 5px 4px rgba(128, 128, 128, 0.138);
          box-shadow: 5px 5px 4px rgba(128, 128, 128, 0.138);
}
.other_offers .cards .card .img {
  position: absolute;
  width: 60%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.other_offers .cards .card:first-child .img {
  -webkit-transform: translate(-50%, -50%) rotate(25deg);
          transform: translate(-50%, -50%) rotate(25deg);
}
.other_offers .cards .card:last-child .img {
  -webkit-transform: translate(-50%, -50%) rotate(5deg);
          transform: translate(-50%, -50%) rotate(5deg);
}