.pages {
  background-color: whitesmoke;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 7px;
  padding: 10px;
}
.pages .card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  padding: 5px;
  -webkit-box-shadow: 0 0 3px rgba(128, 128, 128, 0.089);
          box-shadow: 0 0 3px rgba(128, 128, 128, 0.089);
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.pages .card:hover {
  scale: 1.01;
  z-index: 3;
  outline: 3px solid rgba(220, 220, 220, 0.497);
}
.pages .card:hover h1 {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  letter-spacing: 2px;
}
.pages .card h1 {
  padding: 20px 0;
}
.pages .card img {
  width: 100%;
  height: 100%;
  aspect-ratio: 7/4;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}