.product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  width: 100%;
}
.product .product-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0823529412);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0823529412);
  padding: 0 5px;
}
.product .product-cont .img {
  position: relative;
  height: 500px;
  width: 49%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  background-color: white;
}
.product .product-cont .img img {
  aspect-ratio: 4/4;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  width: 100%;
}
.product .product-cont .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  width: 49%;
}
.product .product-cont .details .title {
  font-size: 40px;
  color: #333;
}
.product .product-cont .details .info {
  font-size: 18px;
  line-height: 30px;
}
.product .product-cont .details .price {
  font-size: 50px;
}
.product .product-cont .details .price p {
  color: #444;
}
.product .product-cont .details .rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 25px;
}
.product .product-cont .details .rate i {
  color: gold;
}
.product .product-cont .details .add-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.product .product-cont .details .add-cart button {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  font-size: 25px;
  padding: 1px 29px;
  border-radius: 5px;
  background-color: rgba(58, 226, 32, 0.826);
  color: ghostwhite;
  border: none;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.149);
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.149);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.product .product-cont .details .add-cart button:hover {
  -webkit-box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.297);
          box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.297);
}
.product .product-cont .details .add-cart button:nth-child(1) {
  background-color: rgba(226, 44, 44, 0.777);
  color: ghostwhite;
}
@media (max-width: 853px) {
  .product .product-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 95%;
    height: 450px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
            box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
  }
}
@media (max-width: 676px) {
  .product .product-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 95%;
    height: 350px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
            box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
  }
}
@media (max-width: 475px) {
  .product .product-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    height: 300px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
            box-shadow: 10px 10px 13px rgba(0, 0, 0, 0.1215686275);
  }
  .product .product-cont .info {
    display: none;
  }
}