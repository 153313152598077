.users {
  width: 100%;
  max-height: 600px;
  margin-top: 20px;
  padding: 0 20px;
}
.users .users-cont {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.users .users-cont::-webkit-scrollbar {
  width: 15px;
}
.users .users-cont::-webkit-scrollbar-thumb {
  background-color: #333;
}
.users .users-cont table {
  background-color: white;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 9px 6px 4px rgba(128, 128, 128, 0.0392156863);
          box-shadow: 9px 6px 4px rgba(128, 128, 128, 0.0392156863);
}
.users .users-cont table tr {
  position: relative;
}
.users .users-cont table tr::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.1607843137);
  left: 0;
}
.users .users-cont table tr td,
.users .users-cont table tr th {
  padding: 15px 0;
}
.users .users-cont table tr .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.users .users-cont table tr .actions .del,
.users .users-cont table tr .actions .upd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.users .users-cont table tr .actions .del:hover,
.users .users-cont table tr .actions .upd:hover {
  background-color: rgba(128, 128, 128, 0.1098039216);
}
.users .users-cont table tr .actions .upd i {
  color: #3f97ff;
}
.users .users-cont table tr .actions .del i {
  color: #ff3f3f;
}
@media (max-width: 676px) {
  .users .users-cont .users-cont {
    overflow: hidden;
  }
}
@media (max-width: 430px) {
  .users .users-cont table {
    font-size: 12px;
  }
  .users .users-cont table tr td {
    padding: 5px 0 !important;
  }
}
.users .popup-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.1882352941);
  z-index: 1;
}
.users .popup-cont .popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  width: 550px;
  height: 650px;
  background-color: white;
  -webkit-box-shadow: 0 0 4px rgba(148, 144, 144, 0.478);
          box-shadow: 0 0 4px rgba(148, 144, 144, 0.478);
  border-radius: 4px;
  padding: 20px 30px;
}
.users .popup-cont .popup .inp {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.users .popup-cont .popup .inp label {
  position: absolute;
  top: -25px;
  color: #333;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input {
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  outline: 1px solid rgba(128, 128, 128, 0.398);
  -webkit-box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.075);
          box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.075);
}
.users .popup-cont .popup .inp input::-webkit-input-placeholder {
  font-size: 15px;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input::-moz-placeholder {
  font-size: 15px;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input:-ms-input-placeholder {
  font-size: 15px;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input::-ms-input-placeholder {
  font-size: 15px;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input::placeholder {
  font-size: 15px;
  text-transform: capitalize;
}
.users .popup-cont .popup .inp input:focus {
  outline: 3px solid rgba(0, 255, 247, 0.571);
}
.users .popup-cont .popup .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
.users .popup-cont .popup .actions .cancel-btn,
.users .popup-cont .popup .actions .send-btn {
  background-color: #ff4545;
  padding: 5px 20px;
  border-radius: 2px;
  color: whitesmoke;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.users .popup-cont .popup .actions .cancel-btn:hover,
.users .popup-cont .popup .actions .send-btn:hover {
  background-color: rgba(255, 69, 69, 0.7215686275);
}
.users .popup-cont .popup .actions .send-btn {
  background-color: #298bff;
}
.users .popup-cont .popup .actions .send-btn:hover {
  background-color: rgba(41, 137, 255, 0.7843137255);
}
.users .popup-cont .popup .select {
  background: ghostwhite;
  outline: 1px solid rgba(128, 128, 128, 0.4588235294);
  border: none;
  padding: 5px;
  font-size: 17px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.users .popup-cont .popup .select:hover {
  outline: 1px solid #333;
}
.users .popup-cont .popup .select option {
  background: whitesmoke;
}