.products-user {
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
}
.products-user .cards {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  gap: 10px;
}
.products-user .cards .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 325px;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 0 3px rgba(128, 128, 128, 0.2156862745);
          box-shadow: 0 0 3px rgba(128, 128, 128, 0.2156862745);
  border-radius: 4px;
  padding: 10px 20px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.products-user .cards .card .imgParent {
  width: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;
}
.products-user .cards .card .imgParent .img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -o-object-fit: scale-down;
     object-fit: scale-down;
  width: 100%;
}
.products-user .cards .card h3 {
  text-align: center;
  color: #333;
}
.products-user .cards .card .desc {
  text-align: center;
  max-width: 100%;
  margin-top: 15px;
}
.products-user .cards .card .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 25px;
}
.products-user .cards .card .info .rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.products-user .cards .card .info .rate i {
  color: gold;
}
.products-user .cards .card .info .price {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  letter-spacing: 1px;
}
.products-user .cards .card:hover {
  cursor: pointer;
  scale: 1.01;
  z-index: 3;
  -webkit-box-shadow: 0px 6px 0 rgba(220, 220, 220, 0.604);
          box-shadow: 0px 6px 0 rgba(220, 220, 220, 0.604);
}
@media (max-width: 734px) {
  .products-user {
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  }
}
@media (max-width: 500px) {
  .products-user .cards .card:hover {
    cursor: pointer;
    scale: unset;
    z-index: unset;
  }
}