.products-layout .products-cont {
  margin-top: 30px;
  width: 100%;
}
.products-layout .products-cont .header-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  gap: 15px;
  background-color: white;
  padding: 20px;
}
.products-layout .products-cont .header-cont .filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.products-layout .products-cont .header-cont .filter select {
  padding: 7px 10px;
  border: none;
  outline: 1px solid #eee;
  -webkit-box-shadow: 0 0 3px rgba(220, 220, 220, 0.2784313725);
          box-shadow: 0 0 3px rgba(220, 220, 220, 0.2784313725);
  font-size: 17px;
  border-radius: 1px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.products-layout .products-cont .header-cont .filter select:hover {
  background-color: rgba(238, 238, 238, 0.5568627451);
}
.products-layout .products-cont .header-cont .filter .cart-link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  background-color: rgba(30, 216, 221, 0.5529411765);
  width: 60px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline: 1px solid rgba(30, 216, 221, 0.5529411765);
  border: none;
}
.products-layout .products-cont .header-cont .filter .cart-link i,
.products-layout .products-cont .header-cont .filter .cart-link p {
  color: rgb(255, 255, 255);
  -webkit-filter: drop-shadow(0 0 3px rgba(220, 220, 220, 0.746));
          filter: drop-shadow(0 0 3px rgba(220, 220, 220, 0.746));
}
.products-layout .products-cont .header-cont .filter .cart-link:hover {
  opacity: 0.8;
}
.products-layout .products-cont .header-cont .search {
  position: relative;
  height: 33px;
  max-width: 250px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 7px rgba(220, 220, 220, 0.4588235294);
          box-shadow: 0px 0px 7px rgba(220, 220, 220, 0.4588235294);
  border-radius: 1px;
  overflow: hidden;
  outline: 1px solid rgba(220, 220, 220, 0.7176470588);
}
.products-layout .products-cont .header-cont .search input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  outline: none;
  border: none;
  font-size: 20px;
}
@media (max-width: 550px) {
  .products-layout .products-cont .header-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    gap: 15px;
    background-color: white;
    padding: 20px;
  }
  .products-layout .products-cont .header-cont .search {
    max-width: none;
    width: 100%;
  }
  .products-layout .products-cont .header-cont .filter {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .products-layout .products-cont .header-cont .filter .cart-link {
    padding: 0 10px;
  }
}
@media (max-width: 450px) {
  .products-layout .products-cont .header-cont {
    overflow: hidden;
  }
  .products-layout .products-cont .header-cont .search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: none;
    width: 100%;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 0;
    outline: 1px;
  }
  .products-layout .products-cont .header-cont .search input {
    position: initial;
    -webkit-box-shadow: 0px 0px 7px rgba(220, 220, 220, 0.4588235294);
            box-shadow: 0px 0px 7px rgba(220, 220, 220, 0.4588235294);
    border-radius: 1px;
    overflow: hidden;
    border: 1px solid rgba(220, 220, 220, 0.7176470588);
    font-size: 15px;
  }
  .products-layout .products-cont .header-cont .search .cart-search {
    margin: 0 2px 0 4px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 10px;
    background-color: rgba(30, 216, 221, 0.5529411765);
    height: 100%;
    width: 60px;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    outline: 1px solid rgba(30, 216, 221, 0.5529411765);
    border: none;
  }
  .products-layout .products-cont .header-cont .search .cart-search i,
  .products-layout .products-cont .header-cont .search .cart-search p {
    color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 0 3px rgba(220, 220, 220, 0.746));
            filter: drop-shadow(0 0 3px rgba(220, 220, 220, 0.746));
  }
  .products-layout .products-cont .header-cont .search .cart-search:hover {
    opacity: 0.8;
  }
  .products-layout .products-cont .header-cont .filter {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 5px;
  }
  .products-layout .products-cont .header-cont .filter select {
    min-width: 18px;
    width: 100%;
    padding: 7px 0;
  }
  .products-layout .products-cont .header-cont .filter .cart-link {
    display: none;
  }
}

.cart-page {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cart-page .cart-products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  height: 500px;
  width: 100%;
  gap: 15px;
}
.cart-page .cart-products .cards {
  height: 100%;
  width: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cart-page .cart-products .cards .card {
  max-width: 700px;
  width: 100%;
  height: 60px;
  background-color: white;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.cart-page .cart-products .cards .card:not(:first-child) {
  margin-top: 15px;
  margin-bottom: 15px;
}
.cart-page .cart-products .cards .card:hover {
  background-color: ghostwhite;
}
.cart-page .cart-products .cards .card .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 60px;
  width: 100%;
}
.cart-page .cart-products .cards .card .img img {
  width: 100%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  aspect-ratio: 4/4;
}
.cart-page .cart-products .cards-info {
  height: 100%;
  width: 30%;
  outline: 1px solid gainsboro;
}
.cart-page .cart-products .cards-info .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 200px;
}
.cart-page .cart-products .cards-info .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  aspect-ratio: 4/4;
}
.cart-page .cart-products .cards-info .item {
  margin: 10px 0;
  font-weight: bold;
  font-size: 23px;
  text-align: center;
}
.cart-page .cart-products .cards-info .item.actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.cart-page .cart-products .cards-info .item.actions button {
  padding: 5px 20px;
  border: none;
  border-radius: 3px;
  color: whitesmoke;
  cursor: pointer;
  font-size: 18px;
  background-color: rgba(54, 54, 246, 0.908);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.cart-page .cart-products .cards-info .item.actions button:first-child {
  background-color: rgba(246, 41, 41, 0.905);
}
.cart-page .cart-products .cards-info .item.actions button:hover {
  opacity: 0.8;
}
.cart-page .nav {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 10px;
  background-color: rgba(240, 55, 55, 0.826);
  color: whitesmoke;
  border-radius: 0 0 4px 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.cart-page .nav:hover {
  opacity: 0.8;
}
@media (max-width: 900px) {
  .cart-page {
    min-height: 175vh;
    -webkit-box-align: normal;
        -ms-flex-align: normal;
            align-items: normal;
  }
  .cart-page .cart-products {
    margin-top: 30px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: 100%;
  }
  .cart-page .cart-products .cards {
    width: 100%;
    height: 100%;
  }
  .cart-page .cart-products .cards .card {
    max-width: none;
    font-size: 13px;
  }
  .cart-page .cart-products .cards-info {
    min-height: 365px;
    max-height: 400px;
    max-width: 500px;
    width: 100%;
    overflow: hidden;
  }
  .cart-page .cart-products .cards-info .item {
    font-size: unset;
  }
  .cart-page .cart-products .cards-info .item.actions button {
    padding: 0;
    border: none;
    border-radius: 3px;
    color: whitesmoke;
    cursor: pointer;
    font-size: 100%;
    background-color: rgba(54, 54, 246, 0.908);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    width: 40%;
    height: 20px;
  }
}
@media (max-width: 455px) {
  .cart-page {
    min-height: 175vh;
    -webkit-box-align: normal;
        -ms-flex-align: normal;
            align-items: normal;
  }
  .cart-page .cart-products {
    margin-top: 30px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .cart-page .cart-products .cards {
    width: 100%;
  }
  .cart-page .cart-products .cards .card {
    max-width: none;
    font-size: 10px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(148, 148, 150, 0.598);
}