.headerDash {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 60px;
  background-color: white;
  padding: 10px 0;
  -webkit-box-shadow: 0 0 9px rgba(220, 220, 220, 0.127);
          box-shadow: 0 0 9px rgba(220, 220, 220, 0.127);
  overflow: hidden;
}
.headerDash .logo {
  position: relative;
  height: 100%;
  min-width: 250px;
  padding: 0 10px;
}
.headerDash .logo::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 0;
  height: 150%;
  width: 1px;
  background-color: rgba(128, 128, 128, 0.0980392157);
}
.headerDash .logo p {
  text-align: center;
  line-height: 40px;
  font-size: 25px;
  color: #333;
}
.headerDash .logo p span {
  color: #00aeff;
}
.headerDash .rightSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.headerDash .rightSide .search {
  position: relative;
  height: 100%;
  width: 500px;
  padding: 2px 0;
}
.headerDash .rightSide .search label {
  position: absolute;
  right: 15px;
  opacity: 0.2;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: text;
}
.headerDash .rightSide .search input {
  width: 100%;
  height: 100%;
  outline: 1px solid rgba(128, 128, 128, 0.563);
  border-radius: 20px;
  border: none;
  padding: 0 15px;
  font-size: 16px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.headerDash .rightSide .search input:focus {
  outline: 1px solid #333;
}
.headerDash .rightSide .other {
  width: 200px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2px 15px;
}
.headerDash .rightSide .other .circle {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 37.5px;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  outline: 1px solid rgba(128, 128, 128, 0.245);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.0784313725);
          box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.0784313725);
}
.headerDash .rightSide .other .circle:not(.profile)::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 0px;
  right: 0px;
  background-color: greenyellow;
  border-radius: 50%;
}
.headerDash .rightSide .other .circle:hover {
  opacity: 0.5;
}
.headerDash .rightSide .other .circle.profile {
  overflow: hidden;
}
.headerDash .rightSide .other .circle.profile img {
  width: 100%;
}
@media (max-width: 900px) {
  .headerDash .rightSide .search {
    width: 300px;
  }
}
@media (max-width: 676px) {
  .headerDash .logo {
    min-width: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .headerDash .rightSide .search {
    width: 200px;
  }
}
@media (max-width: 492px) {
  .headerDash .rightSide .search {
    width: 50%;
  }
}
@media (max-width: 364px) {
  .headerDash .rightSide .search input::-webkit-input-placeholder {
    color: transparent;
  }
  .headerDash .rightSide .search input::-moz-placeholder {
    color: transparent;
  }
  .headerDash .rightSide .search input:-ms-input-placeholder {
    color: transparent;
  }
  .headerDash .rightSide .search input::-ms-input-placeholder {
    color: transparent;
  }
  .headerDash .rightSide .search input::placeholder {
    color: transparent;
  }
}