.header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 50px;
  height: 60px;
  max-width: 1400px;
  width: 100%;
  text-transform: capitalize;
}
.header .logo {
  padding-right: 20px;
  font-size: 23px;
  color: #333;
  text-transform: lowercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.header .logo span:nth-child(2) {
  color: #00aeff;
  text-transform: capitalize;
}
.header .search {
  position: relative;
  width: 40%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  background: white;
  color: #333;
  -webkit-filter: drop-shadow(0 2px 3px rgba(128, 128, 128, 0.163));
          filter: drop-shadow(0 2px 3px rgba(128, 128, 128, 0.163));
}
.header .search label {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.header .search label i {
  opacity: 0.6;
}
.header .search input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 10px;
}
.header .search input::-webkit-input-placeholder {
  text-transform: capitalize;
  opacity: 0.5;
}
.header .search input::-moz-placeholder {
  text-transform: capitalize;
  opacity: 0.5;
}
.header .search input:-ms-input-placeholder {
  text-transform: capitalize;
  opacity: 0.5;
}
.header .search input::-ms-input-placeholder {
  text-transform: capitalize;
  opacity: 0.5;
}
.header .search input::placeholder {
  text-transform: capitalize;
  opacity: 0.5;
}
.header .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: 230px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.746);
  font-weight: 600;
}
.header .options .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: 100%;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.746);
  font-weight: normal;
  font-size: 15px;
}
.header .options .row li {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header .options .row li:hover {
  opacity: 0.7;
}
.header .options .row .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .options .row .status .Link {
  font-size: 15px;
  font-weight: normal;
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header .options .row .status .Link:hover {
  opacity: 0.7;
}
.header .options .row .status p {
  padding: 0 5px;
}
.header .options .list {
  display: none;
  cursor: pointer;
  padding: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header .options .list:hover {
  opacity: 0.7;
}
.header .drop_list {
  z-index: 5;
  position: absolute;
  bottom: -200px;
  right: 13px;
  width: 150px;
  height: 200px;
  background: white;
  -webkit-filter: drop-shadow(0px 2px 2px rgba(128, 128, 128, 0.563));
          filter: drop-shadow(0px 2px 2px rgba(128, 128, 128, 0.563));
  border-radius: 2px 0 2px 2px;
  padding: 0 7px;
}
.header .drop_list::after {
  content: "";
  position: absolute;
  top: -15px;
  right: 0;
  border: 8px solid;
  border-color: transparent transparent white;
}
.header .drop_list ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  background: rgba(246, 246, 246, 0.3411764706);
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 3px rgba(128, 128, 128, 0.2901960784);
          box-shadow: 0px 1px 3px rgba(128, 128, 128, 0.2901960784);
  margin: 10px 0;
}
.header .drop_list ul li button {
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header .drop_list ul li button:hover {
  opacity: 0.7;
}
.header .drop_list ul li p {
  padding: 0 5px;
}
@media (max-width: 767px) {
  .header .options {
    width: 200px;
  }
}
@media (max-width: 676px) {
  .header {
    padding: 10px;
  }
  .header .options {
    width: 20px;
  }
  .header .options .row {
    display: none;
  }
  .header .options .list {
    display: block;
  }
}
@media (max-width: 480px) {
  .header .search {
    width: 90%;
  }
  .header .options {
    padding: 10px 15px;
    padding-right: 5px;
  }
}