* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  list-style: none;
  font-family: sans-serif;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-transform: none;
  text-decoration: none;
  color: black;
}

body {
  max-width: 1400px;
  background-color: #f4f5f9;
  margin: 0 auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
