.bigScreen {
  background: #ececec;
  height: 500px;
  position: relative;
  overflow: hidden;
  padding-left: 10px;
}
.bigScreen .commentCards {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: auto;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 900px;
  -webkit-filter: brightness(0.98);
          filter: brightness(0.98);
}
.bigScreen .commentCards .img {
  width: 100%;
  height: 100%;
}
.bigScreen .commentCards .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: white;
  width: 250px;
  height: 135px;
  position: absolute;
  border-radius: 6px;
  -webkit-filter: drop-shadow(6px 3px 3px rgba(128, 128, 128, 0.1058823529));
          filter: drop-shadow(6px 3px 3px rgba(128, 128, 128, 0.1058823529));
  padding: 15px 25px;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.bigScreen .commentCards .card .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
}
.bigScreen .commentCards .card .user i {
  font-size: 20px;
}
.bigScreen .commentCards .card .comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bigScreen .commentCards .card .rate {
  color: gold;
}
.bigScreen .commentCards .card .rate .fa-star star-half-stroke,
.bigScreen .commentCards .card .rate .emp {
  color: gray;
}
.bigScreen .commentCards .card:hover {
  z-index: 10;
}
.bigScreen .commentCards .card1 {
  top: 15%;
  right: 5%;
}
.bigScreen .commentCards .card2 {
  top: 45%;
  left: 10%;
}
.bigScreen .commentCards .card3 {
  top: 55%;
  right: 8%;
}
@media (max-width: 600px) {
  .bigScreen .commentCards .card3 {
    top: 75%;
  }
}
.bigScreen .desc {
  position: absolute;
  min-width: 550px;
  bottom: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
}
.bigScreen .desc h2 {
  font-size: 35px;
  letter-spacing: 2px;
}
.bigScreen .desc h2 span {
  color: #00aeff;
}
.bigScreen .desc button {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.7215686275);
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 9px 20px;
  font-size: 17px;
  border-radius: 4px;
  border: none;
  -webkit-filter: drop-shadow(7px 7px 3px rgba(128, 128, 128, 0.1058823529));
          filter: drop-shadow(7px 7px 3px rgba(128, 128, 128, 0.1058823529));
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.bigScreen .desc button:hover {
  -webkit-filter: drop-shadow(7px 7px 0px rgba(128, 128, 128, 0.168627451));
          filter: drop-shadow(7px 7px 0px rgba(128, 128, 128, 0.168627451));
  letter-spacing: 1px;
}
.bigScreen .desc .info {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  gap: 25px;
  margin-top: 13px;
  bottom: 40px;
}
.bigScreen .desc .info .info_item p {
  text-transform: capitalize;
}
.bigScreen .desc .info .info_item .range {
  height: 50px;
  width: 50px;
  margin: auto;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px rgba(128, 128, 128, 0.2235294118);
          box-shadow: 0 0 5px rgba(128, 128, 128, 0.2235294118);
  border-radius: 50%;
  line-height: 51px;
  margin-top: 5px;
}
@media (max-width: 600px) {
  .bigScreen .commentCards .card3 {
    display: none;
  }
  .bigScreen .info {
    bottom: 10px !important;
  }
}